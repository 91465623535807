export const SETTINGS_URL = 'admin/settings/user-profile' as const
export const DASHBOARD_URL = 'platform/dashboard' as const
export const LOGIN_URL = 'login' as const
export const REQUISITION_URL = 'platform/requisitionDetails' as const
export const NEW_REQ_URL = 'platform/requisitionNew' as const
export const CAREERS_URL = '/careers' as const

export const CANDIDATE_URL = 'platform/candidates' as const
export const CANDIDATEFORCOMPANY_URL = 'platform/companyCandidates'
export const COMPANY_URL = 'platform/companies' as const
export const CONTACT_URL = 'platform/contacts' as const
export const JOB_URL = 'platform/jobs' as const
export const COMPANYPORTAL_URL = '/platform/companyCandidates' as const
export const JOB_APPLICANTURL = 'platform/jobApplicant' as const
export const JOB_COMPANYURL = 'platform/companyApplicants' as const
export const REPORT_URL = 'platform/reports' as const
export const BILLING_URL = 'platform/billing' as const
export const ONBOARDING_URL = 'platform/onboarding' as const

export const HOME_URL = 'platform/home' as const

export const BILLING_SETTINGS_URL = 'platform/billingSetting' as const
export const BILLING_ITEM_URL = 'platform/billingItems' as const
export const BILLING_INVOICES = 'platform/invoices' as const
export const BILLINGCOMPANY_INVOICES = 'platform/companyInvoices' as const

export const NOTIFICATIONS_URL = 'platform/notifications' as const
