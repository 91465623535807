import './polyfill'
import {createRoot} from 'react-dom/client'
import './index.css'
import registerServiceWorker from './registerServiceWorker'
import * as WebFont from 'webfontloader'
import {CAREERS_URL} from 'lib/constants/urls'

WebFont.load({
  google: {
    families: ['Roboto:300,400,600,700']
  },
  active: () => {
    if (window.location.href.includes(CAREERS_URL)) {
      import('./appInitializer').then((appInitializer) => {
        appInitializer.init().then(() => {
          import('./externalApp').then((externalApp) => {
            const ExternalApp = externalApp.default
            const container = document.getElementById('root') as HTMLElement
            const root = createRoot(container)
            root.render(<ExternalApp />)
          })
        })
      })
    } else {
      import('./appInitializer').then((appInitializer) => {
        appInitializer.init().then(() => {
          import('./App').then((App) => {
            const MainApp = App.default
            const container = document.getElementById('root') as HTMLElement
            const root = createRoot(container)
            root.render(<MainApp />)
          })
        })
      })
    }

    registerServiceWorker()
  }
})
